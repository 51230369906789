import MenuIcon from '@mui/icons-material/Menu';
import { Box, LinearProgress, Menu, MenuItem, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { DataGrid, GridCellParams, GridColDef, GridTreeNode } from '@mui/x-data-grid';
import camelcaseKeys from 'camelcase-keys';
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { IVKI } from '../VKI/Interfaces/IVKI';
import { localizedTextsMap } from './common/LocalizedTextMap';
import { environment } from './common/env';


export const Boer: React.FC = (props:any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
	const [data, setData] = useState<IVKI[] | null>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [showAll, setShowAll] = useState<boolean>(false);
  
  // console.log('environment ' + (environment.environment as string));
  // console.log('AD_B2C_ApplicationId ' + (environment.AD_B2C_ApplicationId as string));
  // console.log('AD_B2C_ReactRedirectUri ' + (environment.AD_B2C_ReactRedirectUri as string));
  // console.log('AD_B2C_SignInPolicy ' + (environment.AD_B2C_SignInPolicy as string));
  // console.log('AD_B2C_Tenant ' + (environment.AD_B2C_Tenant as string));
  // console.log('apiBaseUrl ' + (environment.apiBaseUrl as string));
  // console.log('backendBaseUrl ' + (environment.backendBaseUrl as string));
  // console.log('test ' + (environment.test as string));
  const fetchData = async (showGoed:boolean) => {
		try {
		  const response = await fetch(environment.backendBaseUrl + 'VKIOverzicht?showall=' + showGoed  + '&type=boer&email=' + props.accountInfo.account.idToken.emails[0] + '&environment=' + environment.environment +  '&code=' + environment.REACT_APP_FUNCTION_KEY,{
        headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}
      });
		  if (response.ok) {
			const jsonData = await response.json();
			
			const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
			setData(camelCasedData);
		  } else {
			// Handle error if needed
			
			console.error('Error:', response.status);
		  }
		} catch (error) {
		  // Handle error if needed 
		  console.error('Error:', error);
		}
	  };
	useEffect(() => {

  
	  fetchData(showAll);
	}, [] || null);
	
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter(item =>
    item.Bedrijfslocatie?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
    item.Stalnummer?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
    item.Opzetdatum?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
    item.Leveringsnummer?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
    item.Slachthuis?.ExternSlachthuis?.toString()?.replace("true", "ja")?.replace("false", "nee")?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
    item.Slachthuis?.Naam?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
    item.Slachthuis?.Plaats?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
	  item.Status?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  const columns:GridColDef<IVKI>[] = [
    { 
      field: 'Bedrijfslocatie',
      headerClassName: 'headerOverviewVKI',
      headerName: t('companylocation')?.toString(),
      minWidth: 250,
      editable: false
    },
    { 
      field: 'Stalnummer',
      headerClassName: 'headerOverviewVKI',
      headerName: t('stalnummer')?.toString(), 
      flex: 1,
      editable: false
    },
    {
      field: 'Opzetdatum',
      headerClassName: 'headerOverviewVKI',
      headerName: t('opzetdatum')?.toString(),
      flex: 1, editable: false,
      renderCell: (params: GridCellParams) => (
        params.value ? new Date(params.value as string).toLocaleDateString('nl-NL') : ''
      ),
    },
    { 
      field: 'Leveringsnummer',
      headerClassName: 'headerOverviewVKI',
      headerName: t('levering')?.toString(),
      flex: 1,
      editable: false
    },
    { 
      field: 'Slachthuis.ExternSlachthuis',
      headerName: t('extern')?.toString(),
      flex: 1,
      editable: false,
      headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
        return params.row.Slachthuis.ExternSlachthuis ? t('ja') : t('nee')
      }
    },
    { 
      field: 'Slachthuis.Naam',
      headerName: t('naamvanslachthuis')?.toString(),
      flex: 1,
      editable: false,
      headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
       return params.row.Slachthuis?.Naam;
      }
    },
    { 
      field: 'Slachthuis.Plaats',
      headerName: t('plaatsvanslachthuis')?.toString(),
      flex: 1,
      editable: false,
      headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
       return params.row.Slachthuis?.Plaats;
      }
    },
    { 
      field: 'Status',
      headerName: t('status')?.toString(),
      flex: 1,
      editable: false,
      headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
        return  params.row.Status == "Nieuw" ? t('new') :  params.row.Status == "In bewerking" ? t('editing') :  params.row.Status == "Ingediend" ? t('final') : t('archive');
      }
    },
    {
      field: 'actions',
      headerClassName: 'headerOverviewVKI',
      headerName: '...',
      minWidth:250,
      flex: 0,
      editable: false, 
      renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (
        <div>
          <Button className="vkibutton" hidden={params.row.IsVrijgegeven}  variant="contained" color="primary" 
            onClick={() => {
              const token = props.accountInfo.jwtIdToken;
              const downloadUrl = `${environment.backendBaseUrl}DownloadPDF?userid=${props.accountInfo.account.idToken.sub}&concept=true&id=${params.row.Id}&code=${environment.REACT_APP_FUNCTION_KEY}${'&environment=' + environment.environment}`;
  
              const options = {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              };
  
              fetch(downloadUrl, options)
              .then( res => res.blob() )
              .then( blob => {
                let myDate:Date = new Date();
                try{
                  if (params.row.Opzetdatum)
                  {
                    myDate = new Date(params.row.Opzetdatum);
                  }
                }
                catch(error){}
                download(blob, params.row.Pluimveehouder?.Naam + "_" + params.row?.Stalnummer + "_" + myDate.getDate() +  myDate.getMonth() + myDate.getFullYear() + ".pdf");
                });
              }}
             >Concept</Button> &nbsp;
          <Button className="vkibuton" hidden={!params.row.IsVrijgegeven}  variant="contained" color="primary"  onClick={() => {
            const token = props.accountInfo.jwtIdToken;
            const downloadUrl = `${environment.backendBaseUrl}DownloadPDF?userid=${props.accountInfo.account.idToken.sub}&id=${params.row.Id}&code=${environment.REACT_APP_FUNCTION_KEY}${'&environment=' + environment.environment}`;

            const options = {
              headers: {
                Authorization: `Bearer ${token}`
              }
            };

            fetch(downloadUrl, options)
              .then( res => res.blob() )
              .then( blob => {
                let myDate:Date = new Date();
                try{
                  if (params.row.Opzetdatum)
                  {
                    myDate = new Date(params.row.Opzetdatum);
                  }
                }
                catch(error){}
                download(blob, params.row.Pluimveehouder.Naam + "_" + params.row.Stalnummer + "_" + myDate.getDate() +  myDate.getMonth() + myDate.getFullYear() + ".pdf");
              });
            }}
             >PDF</Button> &nbsp;
				<Button className="vkibuton" variant="contained" color="primary" 
        onClick={() => navigate("/VKI/" + params.row.Id)}>{params.row.Status != "Nieuw" &&  params.row.Status != "In bewerking" ? t('vkiSee') : t('edit')}</Button>
        </div>
      ),
    },

  ];
	const options:Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };


  return (
    <div className='VKIOverzicht'>
        <Button
        hidden={props.role == "AGRI Lezen" }
        id="basic-button"
        aria-controls={open2 ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? 'true' : undefined}
        variant="contained" color="primary"
        onClick={handleClick2}
        startIcon={<MenuIcon />}
        className='menuButton'
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem hidden={props.role == "AGRI Lezen"} onClick={() => {setAnchorEl2(null);  setShowAll(false); localStorage.setItem("pagina", "nieuwvki");fetchData(false);}}>Nieuwe VKI's</MenuItem> 
        <MenuItem hidden={props.role == "AGRI Lezen"} onClick={() => {setAnchorEl2(null);  setShowAll(true); localStorage.setItem("pagina", "goedgekeurd");fetchData(true);}}>Goedgekeurde VKI's</MenuItem> 
        </Menu>
      <TextField
        label={t('Zoeken')}
        value={searchTerm}
        onChange={handleSearch}
        variant="outlined"
        style={{ marginBottom: '1rem' }}
      />
      <Box sx={{ overflow: 'auto', width: '100%' }}>
      <DataGrid
              key={filteredData ? filteredData.length : "loading"}
              getRowId={(row) => row.Id}
              rows={filteredData || []}
              columns={columns}
              rowHeight={30}
              unstable_ignoreValueFormatterDuringExport={true}
              pageSizeOptions={[{ value: 100, label: "100" }, { value: 25, label: "25" },  { value: 10, label: "10" }]}
              localeText={localizedTextsMap}
              slotProps={{
                pagination: {
                  labelRowsPerPage: "Rijen per pagina"
                }
              }}
      />
      </Box>
      <Box hidden={data != null} sx={{ width: '100%' }}> <LinearProgress  /> </Box>
    </div>
  );
};

export default Boer;